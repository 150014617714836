const BASE_URL = process.env.REACT_APP_URL_API;
const fetchProjetsList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/projets?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};
const fetchVarietesList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/variete?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const fetchFieldsList = async ({ queryKey }) => {
  const [_, lang, page] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/field?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const fetchProjetDetail = async ({ queryKey }) => {
  const { lang, id } = queryKey[1];
  if (!id) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/projet-detail?projetId=${id}&lang=${lang}`
  );
  return apiRest.json();
};

const fetchAllFieldsAndVarietes = async ({ queryKey }) => {
  const lang = queryKey[1];
  if (!lang) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/all-fields-and-varietes?lang=${lang}`
  );
  return apiRest.json();
};

const postVariete = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/variete`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateVariete = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/variete?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteVariete = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/planification/variete?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const postChamp = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateChamp = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteChamp = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

export {
  fetchProjetsList,
  fetchVarietesList,
  fetchFieldsList,
  fetchProjetDetail,
  fetchAllFieldsAndVarietes,
  postVariete,
  updateVariete,
  deleteVariete,
  postChamp,
  updateChamp,
  deleteChamp,
};
