import { useQuery } from "@tanstack/react-query";
import { fetchCommandes } from "services/commandesApi";

const useQuerieCommandeList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["commandes", lang, page],
    queryFn: fetchCommandes,
  });
  let datas = [];
  if (data?.data) {
    datas = data?.data.rows.map((item) => {
      return {
        ...item,
        key: item.id,
      };
    });
  }
  return [{ data: datas, dataCount: data?.data.count }, isPending];
};
export { useQuerieCommandeList };
