import { useQuery } from "@tanstack/react-query";
import {
  fetchEducationListCategory,
  fetchEducationOneCategory,
  fetchEducationQuestionCategory,
} from "services/educationApi";
const useQuerieEducationCategoriesList = (lang, page) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["education-list-categories", lang, page],
    queryFn: fetchEducationListCategory,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};

const useQuerieEducationOneCategory = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["category-with-videos", params],
    queryFn: fetchEducationOneCategory,
  });
  return [data?.data ?? {}, isFetching];
};

const useQuerieEducationCategoryQuestions = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["category-with-questions", params],
    queryFn: fetchEducationQuestionCategory,
  });

  return [data?.data ?? {}, isFetching];
};

export {
  useQuerieEducationCategoriesList,
  useQuerieEducationOneCategory,
  useQuerieEducationCategoryQuestions,
};
