const BASE_URL = process.env.REACT_APP_URL_API;
const fetchMarcheCategorieProduit = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/marche/categorie?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};
const postMarcheCategoryProduit = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/marche/categorie`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateMarcheCategoryProduit = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/marche/categorie?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteMarcheCategoryProduit = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/marche/categorie?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const fetchMarcheCategoryWithProducts = async ({ queryKey }) => {
  const { catId, lang, page } = queryKey[1];

  if (!lang || !catId || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/marche/category-products?lang=${lang}&catId=${catId}&page=${page}`
  );
  return apiRest.json();
};

const fetchAllStatiqueData = async () => {
  const apiRest = await fetch(`${BASE_URL}/statique/all`);
  return apiRest.json();
};

const postMarcheProduct = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/marche/produit`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateMarcheProduct = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/marche/produit?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteMarcheProduct = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/marche/produit?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

export {
  fetchMarcheCategorieProduit,
  postMarcheCategoryProduit,
  updateMarcheCategoryProduit,
  deleteMarcheCategoryProduit,
  fetchMarcheCategoryWithProducts,
  fetchAllStatiqueData,
  postMarcheProduct,
  updateMarcheProduct,
  deleteMarcheProduct,
};
