const FILTRE_RAPPORT = {
  form: {
    vertical: false,
    space: {
      direction: "horizital",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [
        {
          value: 1,
          label: "Rapport d'installation",
        },
        {
          value: 2,
          label: "Rapport de connexion",
        },
        {
          value: 3,
          label: "Rapport des synchronisations",
        },
        {
          value: 4,
          label: "Rapport de la visualisation des vidéos",
        },
        {
          value: 5,
          label: "Rapport des réponses aux questions",
        },
        {
          value: 6,
          label: "Rapport des projets synchronisés",
        },
        {
          value: 7,
          label: "Rapport des commandes",
        },
      ],
      width: "200px",
      name: "typeRapportId",
      label: "Type de rapport",
    },
    {
      type: "date",
      direction: "vertical",
      width: "200px",
      label: "Date Debut",
      name: "dateDebut",
      placeholder: "",
      format: "YYYY-MM-DD",
    },
    {
      type: "date",
      direction: "vertical",
      width: "200px",
      label: "Date Fin",
      name: "dateFin",
      placeholder: "",
      format: "YYYY-MM-DD",
    },
  ],
};
const LOGIN_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Nom d'utilisateur",
      name: "username",
      placeholder: "Nom d'utilisateur",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Mot de passe",
      name: "password",
      placeholder: "Mot de passe",
      width: "100%",
      secured: true,
    },
  ],
};
const ADD_VIDEO_USAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Déscription",
      name: "description",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "file",
      direction: "vertical",
      label: "Photo Poster",
      name: "image",
      placeholder: "Séléctionné l'image poster",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Video d'utilisation",
      name: "video",
      placeholder: "Séléctionné la video",
      width: "100%",
      acceptList: ".mov,.mp4,.avi,.vmf,.flv,.webm,.m4v,.x-m4v",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_VIDEO_USAGE_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Déscription",
      name: "description",
      placeholder: "Tapez ici...",
      width: "100%",
    },
  ],
};
const ADD_EDUCATION_CATEGORY_FROM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description",
      width: "100%",
      secured: false,
    },
    {
      type: "radio",
      direction: "vertical",
      name: "have_question",
      label: "Possède de question ?",
      width: "100%",
      options: [
        {
          value: 1,
          name: "OUI",
        },
        {
          value: 0,
          name: "NON",
        },
      ],
    },
    {
      type: "file",
      direction: "vertical",
      label: "Image Poster",
      name: "icone",
      placeholder: "Séléctionné l'image ou une icone",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_EDUCATION_CATEGORY_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_VIDEO_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Photo Poster",
      name: "image",
      placeholder: "Séléctionné l'image poster",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Video formation",
      name: "video",
      placeholder: "Séléctionné la video",
      width: "100%",
      acceptList: ".mov,.mp4,.avi,.vmf,.flv,.webm,.m4v,.x-m4v",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_VIDEO_EDUCATION_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Titre de la vidéo",
      name: "title",
      placeholder: "Titre",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_QUESTION_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [
        { value: 1, label: "Reflexion" },
        { value: 2, label: "Evaluation" },
      ],
      width: "200px",
      name: "typeQuestion",
      label: "Type de question",
    },
  ],
};
const ADD_QUESTION_REFLEXION_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },

    {
      type: "file",
      direction: "vertical",
      label: "Audio Voif Off",
      name: "audio",
      placeholder: "Séléctionné un audio",
      width: "100%",
      acceptList: ".mp3",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_QUESTION_EVALUATION_EDUCATION_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "select",
      direction: "vertical",
      options: [
        { value: 0, label: "Faux" },
        { value: 1, label: "Vrai" },
      ],
      width: "200px",
      name: "response",
      label: "Reponse à la question",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire bonne reponse",
      name: "description_true",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire fausse reponse",
      name: "description_false",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "file",
      direction: "vertical",
      label: "Audio Voif Off",
      name: "audio",
      placeholder: "Séléctionné un audio",
      width: "100%",
      acceptList: ".mp3",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_QUESTION_REFLEXION_EDUCATION_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },
  ],
};
const ADD_QUESTION_EVALUATION_EDUCATION_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Question",
      name: "question",
      placeholder: "Tapez ici...",
      width: "100%",
    },

    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire bonne reponse",
      name: "description_true",
      placeholder: "Tapez ici...",
      width: "100%",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Commentaire fausse reponse",
      name: "description_false",
      placeholder: "Tapez ici...",
      width: "100%",
    },
  ],
};
const UPDATE_COMMANDE_STATUS_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "horizontal",
      options: [
        { value: 0, label: "En Attente" },
        { value: 1, label: "Valider" },
        { value: 2, label: "Annuler" },
      ],
      width: "200px",
      name: "status",
      label: "Changer status",
    },
  ],
};
const ADD_MARCHER_CATEGORY_PRODUIT_FROM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description",
      width: "100%",
      secured: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Image Poster du produit",
      name: "logo",
      placeholder: "Séléctionné l'image ",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_MARCHER_CATEGORY_PRODUIT_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Description",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_MARCHE_PRODUIT_ONE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Nom de l'article",
      name: "title",
      placeholder: "Nom de l'article",
      width: "100%",
      secured: false,
    },

    {
      type: "input",
      direction: "vertical",
      label: "Prix",
      name: "prix",
      placeholder: "Prix",
      width: "100%",
      secured: false,
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "provinceId",
      label: "Province",
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "communeId",
      label: "Commune",
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "zoneId",
      label: "Zone",
    },
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "collineId",
      label: "Colline",
    },
  ],
};
const ADD_MARCHE_PRODUIT_SECOND_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "localiteId",
      label: "Localité",
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Tapez ici la description...",
      width: "100%",
    },
    {
      type: "file",
      direction: "vertical",
      label: "Image Poster du produit",
      name: "image",
      placeholder: "Séléctionné l'image ",
      width: "100%",
      acceptList: ".png,.jpeg,.jpg",
      acceptMultipleFile: false,
    },
    {
      type: "file",
      direction: "vertical",
      label: "Video descriptive",
      name: "video",
      placeholder: "Séléctionné la video",
      width: "100%",
      acceptList: ".mov,.mp4,.avi,.vmf,.flv,.webm,.m4v,.x-m4v",
      acceptMultipleFile: false,
    },
  ],
};
const ADD_MARCHE_PRODUCT_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom de l'article",
      name: "title",
      placeholder: "Nom de l'article",
      width: "100%",
      secured: false,
    },
    {
      type: "textarea",
      direction: "vertical",
      label: "Description",
      name: "description",
      placeholder: "Tapez ici la description...",
      width: "100%",
    },
  ],
};
const ADD_PLANIFICATION_VARIETE_FIRST_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Nom de la varièté",
      name: "description",
      placeholder: "Nom de la varièté",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Durée recolte en mois",
      name: "duree",
      placeholder: "Durée recolte en mois",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Poids unitaire en Kg",
      name: "poidsUnitaire",
      placeholder: "Poids unitaire en Kg",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Taux de survi",
      name: "tauxDeSurvi",
      placeholder: "Taux de survi",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_PLANIFICATION_VARIETE_SECOND_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "input",
      direction: "vertical",
      label: "Nombre d'alevins par m2",
      name: "nbreAlevinMetre",
      placeholder: "Nombre d'alevins par m2",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Prix Unitaire",
      name: "prixUnitaire",
      placeholder: "Prix Unitaire",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Qte nourriture consommée par Alevin (QN/J/A) en Kg",
      name: "nourritureQteParJourParAlevinParKg",
      placeholder: "Qte nourriture consommée par Alevin (QN/J/A) en Kg",
      width: "100%",
      secured: false,
    },
    {
      type: "input",
      direction: "vertical",
      label: "Prix moyen nourriture P/Kg",
      name: "nourriturePrixMoyenParKg",
      placeholder: "Prix moyen nourriture P/Kg",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_PLANIFICATION_VARIETE_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom de la varièté",
      name: "description",
      placeholder: "Nom de la varièté",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_CHAMPS_FIRST_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "select",
      direction: "vertical",
      options: [
        { value: 1, label: "Coût fixe" },
        { value: 2, label: "Coût variable" },
      ],
      width: "200px",
      name: "type",
      label: "Type champs",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom du champs",
      name: "description",
      placeholder: "Nom du champs",
      width: "100%",
      secured: false,
    },
  ],
};
const ADD_CHAMPS_SECOND_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
    {
      type: "radio",
      direction: "vertical",
      name: "isNourriture",
      label: "Est un champs du type nourriture ?",
      width: "100%",
      options: [
        {
          value: 1,
          name: "OUI",
        },
        {
          value: 0,
          name: "NON",
        },
      ],
    },
  ],
};
const ADD_CHAMPS_OTHER_LANGUAGE_FORM = {
  form: {
    vertical: true,
    space: {
      direction: "vertical",
      size: "middle",
    },
  },
  fields: [
     {
      type: "select",
      direction: "vertical",
      options: [],
      width: "200px",
      name: "lang",
      label: "Langue",
    },
    {
      type: "input",
      direction: "vertical",
      label: "Nom du champs",
      name: "description",
      placeholder: "Nom du champs",
      width: "100%",
      secured: false,
    },
  ],
};
const FORM_CONFIG = {
  FILTRE_RAPPORT,
  LOGIN_FORM,
  ADD_VIDEO_USAGE_FORM,
  ADD_VIDEO_USAGE_OTHER_LANGUAGE_FORM,
  ADD_EDUCATION_CATEGORY_FROM,
  ADD_EDUCATION_CATEGORY_OTHER_LANGUAGE_FORM,
  ADD_VIDEO_EDUCATION_FORM,
  ADD_VIDEO_EDUCATION_OTHER_LANGUAGE_FORM,
  ADD_QUESTION_EDUCATION_FORM,
  ADD_QUESTION_REFLEXION_EDUCATION_FORM,
  ADD_QUESTION_EVALUATION_EDUCATION_FORM,
  ADD_QUESTION_REFLEXION_EDUCATION_OTHER_LANGUAGE_FORM,
  ADD_QUESTION_EVALUATION_EDUCATION_OTHER_LANGUAGE_FORM,
  UPDATE_COMMANDE_STATUS_FORM,
  ADD_MARCHER_CATEGORY_PRODUIT_FROM,
  ADD_MARCHER_CATEGORY_PRODUIT_OTHER_LANGUAGE_FORM,
  ADD_MARCHE_PRODUIT_ONE_FORM,
  ADD_MARCHE_PRODUIT_SECOND_FORM,
  ADD_MARCHE_PRODUCT_OTHER_LANGUAGE_FORM,
  ADD_PLANIFICATION_VARIETE_FIRST_FORM,
  ADD_PLANIFICATION_VARIETE_SECOND_FORM,
  ADD_PLANIFICATION_VARIETE_OTHER_LANGUAGE_FORM,
  ADD_CHAMPS_FIRST_FORM,
  ADD_CHAMPS_SECOND_FORM,
  ADD_CHAMPS_OTHER_LANGUAGE_FORM
};
export default FORM_CONFIG;
