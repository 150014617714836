import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute, LoadingSuspense } from "utils";
const LoginPage = lazy(() => import("./pages/auths/LoginPage"));
const DashboardPage = lazy(() => import("./pages/dashboard/DashboardPage"));
const UsagePage = lazy(() => import("./pages/usages/UsagePage"));
const UsageDetailPage = lazy(() => import("./pages/usages/UsageDetailPage"));
const EducationPage = lazy(() => import("./pages/education/EducationPage"));
const EducationVideoPage = lazy(() =>
  import("./pages/education/EducationVideoPage")
);
const EducationQuestionPage = lazy(() =>
  import("./pages/education/EducationQuestionPage")
);
const MarchePage = lazy(() => import("./pages/marches/MarchePage"));
const MarcheProduitPage = lazy(() =>
  import("./pages/marches/MarcheProduitPage")
);
const PlanificationPage = lazy(() =>
  import("./pages/planifications/PlanificationPage")
);
const RapportPage = lazy(() => import("./pages/rapport/RapportPage"));
const ErrorPage404 = lazy(() => import("./pages/error/ErrorPage404"));

const App = () => {
  return (
    <Suspense fallback={<LoadingSuspense />}>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<DashboardPage />} />
          <Route path="/usage" element={<UsagePage />} />
          <Route path="/usage-video-detail/:id" element={<UsageDetailPage />} />
          <Route path="/education" element={<EducationPage />} />
          <Route
            path="/education-videos/:id"
            element={<EducationVideoPage />}
          />
          <Route
            path="/education-questions/:id"
            element={<EducationQuestionPage />}
          />
          <Route path="/marches" element={<MarchePage />}>
            {["categorie-produit", "commandes"].map((path) => (
              <Route key={path} path={path} element={<MarchePage />} />
            ))}
          </Route>
          <Route path="/marches-produits/:id" element={<MarcheProduitPage />} />
          <Route path="/planifications" element={<PlanificationPage />}>
            {["projet", "varietes", "config-fields", "projet-detail/:id"].map(
              (path) => (
                <Route key={path} path={path} element={<PlanificationPage />} />
              )
            )}
          </Route>
          <Route path="/rapports" element={<RapportPage />} />
          <Route path="*" element={<ErrorPage404 />} />
        </Route>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
    </Suspense>
  );
};

export default App;
