import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginUser } from "../redux";

import { NotificationBoxContext } from "context";
import { loginUser } from "services/authApi";
import {
  postUsageVideo,
  deleteUsageVideo,
  updateUsageVideo,
} from "services/usageApi";
import {
  postEducationCategory,
  deleteEducationCategory,
  updateEducationCategory,
  postEducationVideo,
  updateEducationVideo,
  deleteEducationVideo,
  postEducationQuestion,
  updateEducationQuestion,
  deleteEducationQuestion,
} from "services/educationApi";
import { updateCommandeStatus, deleteCommande } from "services/commandesApi";

import {
  postMarcheCategoryProduit,
  updateMarcheCategoryProduit,
  deleteMarcheCategoryProduit,
  postMarcheProduct,
  updateMarcheProduct,
  deleteMarcheProduct,
} from "services/marcheApi";

import {
  postVariete,
  updateVariete,
  deleteVariete,
  postChamp,
  updateChamp,
  deleteChamp,
} from "services/planificationApi";

const usePostAuthenticationUser = () => {
  const openNotification = useContext(NotificationBoxContext);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "failed") {
        openNotification({ status, msg });
      }
      if (status === "success") {
        const userAuth = data.data;
        dispatch(
          setLoginUser({
            dataUser: userAuth.data,
            isConnected: true,
            token: userAuth.token,
          })
        );
        return navigateTo("/dashboard");
      }
    },
  });
};

//COMMANDES MUTATIONS
const useUpdateCommandeStatus = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateCommandeStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["commandes"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

// USAGES MUTATIONS : CREATE, UPDATE, DELETE

const useDeleteCommande = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteCommande,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["commandes"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const usePostUsageVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postUsageVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["usages-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const useUpdateUsageVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateUsageVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["usages-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const useDeleteUsageVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteUsageVideo,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["usages-videos"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

// EDUCATION MUTATIONS
const usePostEducationCategory = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postEducationCategory,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["education-list-categories"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteEducationCategory = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteEducationCategory,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["education-list-categories"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateEducationCategory = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateEducationCategory,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["education-list-categories"]);
        queryClient.invalidateQueries(["category-with-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const usePostEducationVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postEducationVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateEducationVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateEducationVideo,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-videos"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const useDeleteEducationVideo = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteEducationVideo,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["category-with-videos"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

const usePostEducationQuestion = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postEducationQuestion,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-questions"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateEducationQuestion = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateEducationQuestion,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-with-questions"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteEducationQuestion = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteEducationQuestion,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["category-with-questions"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

// MARCHES MUTATIONS
const usePostMarcheCategorieProduit = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postMarcheCategoryProduit,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["categorie-produit"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateMarcheCategoryProduit = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateMarcheCategoryProduit,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["categorie-produit"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteMarcheCategoryProduit = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteMarcheCategoryProduit,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["categorie-produit"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const usePostMarcheProduct = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postMarcheProduct,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-product-with-products"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateMarcheProduct = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateMarcheProduct,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["category-product-with-products"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteMarcheProduct = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteMarcheProduct,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["category-product-with-products"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};

//PLANIFICATION MUTATIONS
const usePostVariete = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postVariete,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["varietes"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateVariete = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateVariete,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["varietes"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteVariete = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteVariete,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["varietes"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const usePostChamps = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: postChamp,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fields"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useUpdateChamp = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: updateChamp,
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        queryClient.invalidateQueries(["fields"]);
      }
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
const useDeleteChamp = () => {
  const queryClient = useQueryClient();
  const openNotification = useContext(NotificationBoxContext);
  return useMutation({
    mutationFn: deleteChamp,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["fields"]);
      const { status, msg } = data;
      openNotification({ status, msg });
    },
    onError: () => {},
  });
};
export {
  useUpdateCommandeStatus,
  useDeleteCommande,
  usePostAuthenticationUser,
  usePostUsageVideo,
  useDeleteUsageVideo,
  useUpdateUsageVideo,
  usePostEducationCategory,
  useDeleteEducationCategory,
  useUpdateEducationCategory,
  usePostEducationVideo,
  useUpdateEducationVideo,
  useDeleteEducationVideo,
  usePostEducationQuestion,
  useUpdateEducationQuestion,
  useDeleteEducationQuestion,
  usePostMarcheCategorieProduit,
  useUpdateMarcheCategoryProduit,
  useDeleteMarcheCategoryProduit,
  usePostMarcheProduct,
  useUpdateMarcheProduct,
  useDeleteMarcheProduct,
  usePostVariete,
  useUpdateVariete,
  useDeleteVariete,
  usePostChamps,
  useUpdateChamp,
  useDeleteChamp,
};
