const BASE_URL = process.env.REACT_APP_URL_API;
const fetchCommandes = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/marche/commandes?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const updateCommandeStatus = async ({ data, id }) => {
  const apiRest = await fetch(
    `${BASE_URL}/marche/update-commande-status?id=${id}`,
    {
      method: "PATCH",
      body: data,
    }
  );
  return apiRest.json();
};

const deleteCommande = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/marche/archiver-commande?id=${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  return apiRest.json();
};
export { fetchCommandes, updateCommandeStatus, deleteCommande };
