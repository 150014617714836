import { useQuery } from "@tanstack/react-query";
import {
  fetchProjetsList,
  fetchVarietesList,
  fetchFieldsList,
  fetchProjetDetail,
  fetchAllFieldsAndVarietes,
} from "services/planificationApi";

const useQueryProjectList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["projets", lang, page],
    queryFn: fetchProjetsList,
  });
  let datas = [];
  if (data?.data) {
    datas = data?.data.rows.map((item) => {
      return {
        ...item,
        key: item.id,
      };
    });
  }
  return [{ data: datas, dataCount: data?.data.count }, isPending];
};

const useQueryVarieteList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["varietes", lang, page],
    queryFn: fetchVarietesList,
  });
  let datas = [];
  if (data?.data) {
    datas = data?.data.rows.map((item) => {
      return {
        ...item,
        key: item.id,
      };
    });
  }
  return [{ data: datas, dataCount: data?.data.count }, isPending];
};

const useQueryFieldsList = (lang, page) => {
  const { data, isPending } = useQuery({
    queryKey: ["fields", lang, page],
    queryFn: fetchFieldsList,
  });
  let datas = [];
  if (data?.data) {
    datas = data?.data.rows.map((item) => {
      return {
        ...item,
        key: item.id,
      };
    });
  }
  return [{ data: datas, dataCount: data?.data.count }, isPending];
};
const useQueryProjetDetail = (id) => {
  const { data, isFetching } = useQuery({
    queryKey: ["projet-detail", id],
    queryFn: fetchProjetDetail,
  });
  return [data?.data ?? [], isFetching];
};

const useQueryAllFieldsAndVarietes = (lang) => {
  const { data, isFetching } = useQuery({
    queryKey: ["fields-and-varietes", lang],
    queryFn: fetchAllFieldsAndVarietes,
  });
  return [data?.data ?? { fields: [], varietes: [] }, isFetching];
};

export {
  useQueryProjectList,
  useQueryVarieteList,
  useQueryFieldsList,
  useQueryProjetDetail,
  useQueryAllFieldsAndVarietes,
};
