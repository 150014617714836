import { useQuery } from "@tanstack/react-query";
import {
  fetchMarcheCategorieProduit,
  fetchMarcheCategoryWithProducts,
  fetchAllStatiqueData,
} from "services/marcheApi";

const useQuerieMarcheCategorieProduit = (lang, page) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["categorie-produit", lang, page],
    queryFn: fetchMarcheCategorieProduit,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};

const useQuerieMarcheCategoryWithProducts = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["category-product-with-products", params],
    queryFn: fetchMarcheCategoryWithProducts,
  });
  return [data?.data ?? {}, isFetching];
};

const useQueryStatiqueData = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["statique-data", params],
    queryFn: fetchAllStatiqueData,
  });

  return [data?.data ?? {}, isFetching];
};

export {
  useQuerieMarcheCategorieProduit,
  useQuerieMarcheCategoryWithProducts,
  useQueryStatiqueData,
};
