const THEME = {
  token: {
    fontSize: 14,
    fontFamily: "RobotoCondesedRegular,  Courier, monospace",
    colorPrimary: "#047857",
  },
  components: {
    Table: {
      headerBg: "#047857",
      headerColor: "#fafafa",
    },
  },
};

export default THEME;
