const DATE = new Date();
const PROJET_CONFIG = {
  appName: "FishCoach",
  zipName: "FishCoach",
  appLongName: "FishCoach",
  location: {
    countryName: "BURUNDI",
    countryCode: "BDI",
    deviceName: "BIF",
  },
  copyRightDescription: function () {
    return `© ${this.appName} - ${DATE.getFullYear()}. Tous droits réservés`;
  },
  pagination: {
    defaultPageSize: 5,
  },
};
export default PROJET_CONFIG;
