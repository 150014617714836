const BASE_URL = process.env.REACT_APP_URL_API;
const fetchEducationListCategory = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/education/categorie?lang=${lang}&page=${page}`
  );
  return apiRest.json();
};

const fetchEducationOneCategory = async ({ queryKey }) => {
  const { id, lang, page } = queryKey[1];

  if (!lang || !id || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/education/one-categorie?lang=${lang}&id=${id}&page=${page}`
  );
  return apiRest.json();
};

const postEducationCategory = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/education/categorie`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateEducationCategory = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/education/categorie?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteEducationCategory = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/education/categorie?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const postEducationVideo = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/education/video`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};
const updateEducationVideo = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/education/video?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};
const deleteEducationVideo = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/education/video?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

const fetchEducationQuestionCategory = async ({ queryKey }) => {
  const { catId, lang, page } = queryKey[1];

  if (!lang || !catId || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/education/category-questions?lang=${lang}&catId=${catId}&page=${page}`
  );
  return apiRest.json();
};

const postEducationQuestion = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/question`, {
    method: "POST",
    body: data,
  });
  return apiRest.json();
};

const updateEducationQuestion = async ({ data, id }) => {
  const apiRest = await fetch(`${BASE_URL}/question?id=${id}`, {
    method: "PATCH",
    body: data,
  });
  return apiRest.json();
};

const deleteEducationQuestion = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/question?id=${id}`, {
    method: "DELETE",
    body: {},
  });
  return apiRest.json();
};

export {
  fetchEducationListCategory,
  postEducationCategory,
  updateEducationCategory,
  deleteEducationCategory,
  fetchEducationOneCategory,
  postEducationVideo,
  updateEducationVideo,
  deleteEducationVideo,
  fetchEducationQuestionCategory,
  postEducationQuestion,
  updateEducationQuestion,
  deleteEducationQuestion,
};
